export default {
  data() {
    return {
      saveBtnClicked: false,
    };
  },
  computed: {
    sumOfWeights() {
      return this.options.reduce((acc, cur) => acc + +cur.weigth, 0);
    },
    isAllZeroed() {
      return this.sumOfWeights === 0;
    },
  },
  methods: {
    probability(weigth) {
      if (!this.sumOfWeights) return 0;
      const chance = (weigth / this.sumOfWeights) * 100;
      return chance.toFixed(2);
    },
    disabled(index) {
      return this.options[index].weigth < 1;
    },
    classes(index, type) {
      return ['simpleInput', 'wheelInput', this.validField(index, type)];
    },
    validField(index, type) {
      if (
        this.saveBtnClicked &&
        this.options[index][type].length === 0 &&
        +this.options[index].weigth > 0
      ) {
        return 'invalid-field';
      }
    },
    isActiveSave() {
      let activeCount = 0;
      this.options.forEach((w) => {
        if (+w.weigth > 0) activeCount++;
      });
      let validFieldNum = 0;
      this.options.forEach((w) => {
        if (w.title.length > 0 && w.couponCode.length > 0) {
          if (+w.weigth > 0) validFieldNum++;
        }
      });
      return activeCount === validFieldNum && !this.isAllZeroed;
    },
    hideFrom(type, formType) {
      if ((type === 'save' && this.isActiveSave()) || type === 'cancel') {
        this.changeFormManagerVisibility({ hide: formType });
      }
    },
  },
};
